import { mapActions, mapMutations, mapState } from "vuex";
import columns from "./columns";

export default {
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      defaultSettings: [], // don't remove this, it's need for createColumnConfigData
    };
  },
  computed: {
    ...mapState({
      locationSyncState: (state) => state.appModule.locationSyncState,
      clients: (state) => state.clientsDeletedModule,
      extendedType: (state) => state.clientsDeletedModule.extendedType,
      dataTable: (state) => state.clientsDeletedModule.dataTable,
      filter: (state) => state.clientsDeletedModule.filter,
    }),

    defaultColumnConfigs() {
      return columns;
    },

    searchValueW: {
      set(value) {
        this.setSearchValue(value);
      },
      get() {
        return this.clients.searchValue;
      },
    },
  },

  methods: {
    ...mapActions({
      getClientList: "clientsDeletedModule/getClientList",
      search: "clientsDeletedModule/search",
    }),
    ...mapMutations({
      setCurrentPage: "clientsDeletedModule/setCurrentPage",
      setRowsPerPageCount: "clientsDeletedModule/setRowsPerPageCount",
      setSearchValue: "clientsDeletedModule/setSearchValue",
      resetState: "clientsDeletedModule/resetState",
    }),

    hideShowColumns() {
      this.columnConfigData.map((item) => {
        if (item.key === "recurringDate") {
          item.isShow = false;
          item.customUnshow = true;
          item.disabled = true;
        }
      });
    },

    async updateRowsPerPageCount(count) {
      this.setRowsPerPageCount(count);
      await this.getClientList();
      this.resetSelectedList();
    },

    async resetDataTableSettings() {
      this.createColumnConfigData();
      this.hideShowColumns();
    },

    async changePage(page) {
      if (page) {
        this.setCurrentPage(page);
      }
      await this.getClientList();
      this.resetSelectedList();
    },
  },

  async created() {
    this.createColumnConfigData();

    try {
      await this.getClientList();
      this.resetSelectedList();
    } catch (err) {
      console.error(err);
    }
  },

  beforeDestroy() {},

  destroyed() {
    this.resetState(this.clients);
  },
};
