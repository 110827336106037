import numberToFinanceNumber from "../../helpers/numberToFinanceNumber";
import { FormatClientAddress } from "../../mixins/AddressFormat";

export default [
  {
    title: "Full Name",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "firstname",
    defaultHide: true,
    defaultDragIndex: 0,
    mainIndex: 0,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.personalInfo
          ? row.personalInfo.firstname + " " + row.personalInfo.lastname
          : "-"
      }</p>`,
  },
  {
    title: "Provider",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    key: "provider",
    defaultHide: true,
    defaultDragIndex: 3,
    mainIndex: 3,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.provider ? row.provider?.name[0]?.name : "-"
      }</p>`,
  },
  {
    title: "Phone Number",
    isTranslate: false,
    style: { minWidth: 200, maxWidth: 260 },
    isShow: true,
    isDrag: true,
    key: "phone",
    defaultHide: true,
    defaultDragIndex: 5,
    mainIndex: 5,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.phones && row.phones.length ? getPhoneNumber(row.phones) : "-"
      }</p>`,
  },
  {
    title: "Email Address",
    isTranslate: false,
    style: { minWidth: 200, maxWidth: 260 },
    isShow: true,
    isDrag: true,
    key: "email",
    defaultHide: true,
    defaultDragIndex: 6,
    mainIndex: 6,
    cellView: (row) =>
      `<p class="G-text-table">${
        row?.emails && row.emails.length ? getEmail(row.emails) : "-"
      }</p>`,
  },
  {
    title: "Address",
    isTranslate: false,
    style: { minWidth: 300 },
    isShow: true,
    isDrag: true,
    key: "address",
    defaultHide: true,
    defaultDragIndex: 8,
    mainIndex: 8,
    cellView: (row) =>
      `<p class="G-text-table">${
        row.addresses && row.addresses.length
          ? getAddress(row.addresses)
          : "-"
      }</p>`,
  },
  {
    title: "Balance",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    className: "P-border-dashed",
    key: "balance",
    defaultHide: true,
    defaultDragIndex: 15,
    mainIndex: 15,
    cellView: (row) =>
      `<p class="G-text-table">
            ${numberToFinanceNumber(row.balance)}
          </p>`,
  },
  {
    title: "Credit",
    isTranslate: false,
    style: { minWidth: 160 },
    isShow: true,
    isDrag: true,
    className: "P-border-dashed",
    key: "credit",
    defaultHide: true,
    defaultDragIndex: 16,
    mainIndex: 16,
    cellView: (row) => `<p class="G-text-table">
        ${
          row.credits && row.credits.length
            ? getCreditAmount(row.credits)
            : "$0.00"
        }
      </p>`,
  },
];

function getEmail(emails) {
  if (emails.length) {
    let isMain = emails.filter((email) => email.isMain)[0];
    if (isMain) {
      return isMain.email;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

function getPhoneNumber(phones) {
  if (phones.length) {
    let forCall = phones.filter((phone) => phone.forCall)[0];
    if (forCall) {
      return forCall.phone;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

function getAddress(addresses) {
  let address = "";
  if (addresses.length) {
    let forContactInvoice = addresses.filter(
      (address) => address.forContactInvoice
    )[0];

    if (forContactInvoice) {
      address += FormatClientAddress(forContactInvoice);
      return address;
    }

    if (!forContactInvoice) {
      address += FormatClientAddress(addresses[0]);
      return address;
    }
  } else {
    return "-";
  }
}

function getCreditAmount(credits) {
  if (credits.length) {
    let credit = credits[0].creditAmount;
    return numberToFinanceNumber(credit);
  }
}
