const numberToFinanceNumber = (number, data = null) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(number);

  if (number === 0) {
    return "$0.00";
  }

  if (data?.redText) {
    return `<span style="color: red;">${formattedNumber}</span>`;
  }

  if (number < 0) {
    return `${formattedNumber}`;
  } else {
    return `<span style="color: #6CCF97;">${formattedNumber}</span>`;
  }
};

export default numberToFinanceNumber;
